import {
    resourceGoals,
    resourceIndustries,
    resourceTags,
    spotonProducts,
  } from "../../constants";
  
  export default {
    pageTitle: "SpotOn Case Study | Bartolino's Fine Italian Cuisine",
    pageDescription:
      "Discover how an Italian restaurant expedites orders and saves thousands of dollars in credit card fees with the SpotOn Restaurant POS system.",
    title: "An old-school restaurant leverages new tech",
    subTitle: `How Bartolino's increases sales and efficiency with SpotOn technology.`,
    businessName: "Bartolino's Fine Italian Cuisine",
    industry: "Restaurants & hospitality, fine dining",
    location: "Queens, New York",
    products: [spotonProducts.RESTAURANT],
    description:
      "For 37 years, Bartolino's has been serving traditional Southern Italian cuisine in the Astoria neighborhood of New York City. And with the help of SpotOn, marketing manager Lawrence Hammel has seen record-breaking sales by implementing a modern restaurant POS system.",
    quoteData: {
      imgName: "bartolinos-fine-italian-cuisine.png",
      quote: `“As far as our bottom line, cash discounting alone pays for SpotOn. With it, we’re saving $1,200 a month, and our equipment costs $3,200. So, in our eyes, it’s paid for in three months.”`,
      personName: "Lawrence Hammel",
      personTitle: "Manager of Marketing and Operations, Bartolino’s",
    },
    goal: `Hammel needed a restaurant POS so Bartolino's could streamline communications between the front-of-house and back-of-house. Also, he was looking for a way to save money on his ever-increasing credit card fees.`,
    solution: `With SpotOn Restaurant, servers can send orders to the kitchen faster while they're instantly routed to the right station. And along with better efficiency, Bartolino's saves hundreds of dollars on credit card fees every month with cash discounts.`,
    results: {
      title: `The results`,
      stats: [
        { title: `2 – 5 minute`, description: `faster ordering times` },
        {
          title: `$1,200`,
          description: `saved on credit card fees every month`,
        },
      ],
    },
  };
  
  export const relatedSuccessStories = [
    {
        title: "A southern restaurant sees profits go north",
        imageName: "southern-table.png",
        industry: resourceIndustries.RESTAURANTS,
        tag: resourceTags.CASE_STUDY,
        date: "2022-02-28",
        href: "/case-studies/southern-table",
        goals: [
          resourceGoals.STREAMLINE_OPERATIONS,
          resourceGoals.GET_SMARTER_DATA,
          resourceGoals.MANAGE_AND_RETAIN_EMPLOYEES,
        ],
    },
    {
        title: "Fine dining reimagined",
        imageName: "imaginate-restaurant.png",
        industry: resourceIndustries.RESTAURANTS,
        tag: resourceTags.CASE_STUDY,
        date: "2022-03-21",
        href: "/case-studies/imaginate-restaurant",
        goals: [
          resourceGoals.STREAMLINE_OPERATIONS,
          resourceGoals.TAKE_PAYMENTS_EVERYWHERE,
      ],
    },
    {
        title: "Steak and tech done to perfection",
        imageName: "deblaze-grill.png",
        industry: resourceIndustries.RESTAURANTS,
        tag: resourceTags.CASE_STUDY,
        date: "2022-04-07",
        href: "/case-studies/deblaze-grill",
        goals: [
          resourceGoals.STREAMLINE_OPERATIONS,
          resourceGoals.CONNECT_AND_SELL_ONLINE,
          resourceGoals.TAKE_PAYMENTS_EVERYWHERE,
        ],
    },
  ];
  